<template>
  <el-dialog title="请答题" :visible="question" v-if="question">
    <el-alert title="回答错误" type="error" v-if="showErrMsg"></el-alert>
    <el-form :model="form" label-position="top">
      <div style="width: 100%;display: block;text-align: left;padding: 10px 20px 10px 40px"><h3>{{ questionTitle }}</h3>
      </div>
      <el-form-item :label-width="formLabelWidth">
        <el-checkbox-group v-model="multiAnswer" size="medium" v-if="question.questionType==='MUTIL'">
          <el-checkbox style="display:block;margin: 0px 0px 0px 60px;text-align: left;"
                       v-for="(item,key) in question.courseEjectOptionList"
                       :key="key"
                       :label="item.courseEjectOptionId">{{ item.optionValue }}-{{ item.optionDesc }}
          </el-checkbox>
        </el-checkbox-group>
        <el-radio-group v-model="singleAnswer" size="medium" v-else>
          <el-radio v-for="(item,key) in question.courseEjectOptionList"
                    :key="key"
                    :label="item.courseEjectOptionId"
                  >{{ item.optionValue }}-{{ item.optionDesc }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
    </div>
  </el-dialog>
</template>
<script>
export default {
  props: {
    question: null,
    currentTime: 0,
  },
  data () {
    return {
      form: {
        name: '',
        region: ''
      },
      multiAnswer: [],
      singleAnswer: [],
      answer: [],
      showErrMsg: false,
      formLabelWidth: '600px',
    }
  },
  computed: {
    dialogFormVisible: {
      get () {
        return !!this.question
      },
    },
    questionTitle: {
      get () {
        return this.question ? '问题：' + this.question.questionTitle : ''
      }
    }
  },
  methods: {
    handleClose () {
      this.$emit('close')
    },
    handleSubmit () {
      console.log('===============', this.multiAnswer)
      this.showErrMsg = false
      let answer = []
      if (this.question.questionType === 'MUTIL') {
        answer = this.multiAnswer
      } else {
        answer.push(this.singleAnswer)
      }
      const params = {
        courseEjectQuestionId: this.question.courseEjectQuestionId,
        studyDuration: this.currentTime,
        answerList: answer
      }
      this.$axios.post(this.$apiUrl.submitEjectQuestion, params).then(res => {
        if (res && res.data.success) {
          if (res.data.data) {
            this.$emit('submit')
          } else {
            this.showErrMsg = true
          }
        } else {

        }
      })
    }
  }
}
</script>
